var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from '../meta-data-decorators';
import { RoleCategories } from '../../roleEnums';
import { TrailMapType } from '../../trail-maps/models';
export var SubmissionStatus;
(function (SubmissionStatus) {
    SubmissionStatus["Draft"] = "draft";
    SubmissionStatus["Submitted"] = "submitted";
    SubmissionStatus["InReview"] = "inreview";
    SubmissionStatus["Approved"] = "approved";
    SubmissionStatus["Declined"] = "declined";
    SubmissionStatus["Denied"] = "denied";
    SubmissionStatus["Accepted"] = "accepted";
    SubmissionStatus["InfoRequested"] = "inforequested";
    SubmissionStatus["Bound"] = "bound";
})(SubmissionStatus || (SubmissionStatus = {}));
export const SubmissionTypes = {
    application: 'application',
    quickQuote: 'quickQuote',
};
const recordTypeId = 'Submission';
let Submission = class Submission {
    id;
    createUserId;
    modifiedUserId;
    deleteUserId;
    createUser;
    createdUTC;
    modifiedUser;
    modifiedUTC;
    deleteUser;
    deletedUTC;
    agentId;
    agent;
    agencyId;
    agency;
    policyId;
    policy;
    policyholderId;
    policyholder;
    applicant;
    status;
    submissionDate;
    effectiveDate;
    expirationDate;
    payrollClassCodes;
    submittedBy;
    submittedById;
    notes;
    billingScheduleTypeId;
    billingScheduleType;
    submissionName;
    //kept in jdata for legacy usage; prefer applicant
    address;
    //kept in jdata for legacy usage; prefer applicant
    federalEin;
    //kept in jdata for legacy usage; prefer applicant or policyholder
    insured;
    earnedPremium;
    totalIncurred;
    lossRatio;
    //kept in jdata until we support more coverages; this may shape differently
    coverages;
    submissionType;
    locationBasedPayroll;
    policyLocations;
    quoteId;
    quote;
};
__decorate([
    FieldData({ recordTypeId, label: 'Submission ID', fieldType: 'uuid' })
], Submission.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], Submission.prototype, "createUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], Submission.prototype, "modifiedUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], Submission.prototype, "deleteUserId", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Created By',
        fieldType: 'User',
    })
], Submission.prototype, "createUser", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Created On',
        fieldType: 'datetime',
    })
], Submission.prototype, "createdUTC", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Modified By',
        fieldType: 'User',
    })
], Submission.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Modified On',
        fieldType: 'datetime',
    })
], Submission.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Deleted By',
        fieldType: 'User',
    })
], Submission.prototype, "deleteUser", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Deleted On',
        fieldType: 'datetime',
    })
], Submission.prototype, "deletedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string' })
], Submission.prototype, "agentId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Agent', fieldType: 'Agent' })
], Submission.prototype, "agent", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], Submission.prototype, "agencyId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Agency', fieldType: 'Agency' })
], Submission.prototype, "agency", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], Submission.prototype, "policyId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Policy', fieldType: 'Policy', jsonStorage: true })
], Submission.prototype, "policy", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], Submission.prototype, "policyholderId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Policyholder', fieldType: 'PolicyHolder', jsonStorage: true })
], Submission.prototype, "policyholder", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Applicant', fieldType: 'Applicant', hasColumn: true })
], Submission.prototype, "applicant", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Status',
        formatter: (v) => {
            switch (v) {
                case SubmissionStatus.InReview:
                    return 'In Underwriter Review';
                case SubmissionStatus.InfoRequested:
                    return 'More Info Requested';
                default:
                    return `${v[0].toUpperCase()}${v.slice(1)}`;
            }
        },
        pathOverride: `case when "{tableAlias}"."status" = 'inreview' then 'In Underwriter Review' when "{tableAlias}"."status" = 'inforequested' then 'Info Requested' else initcap("{tableAlias}"."status") end`,
    })
], Submission.prototype, "status", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Date Submitted', fieldType: 'date' })
], Submission.prototype, "submissionDate", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Effective Date', fieldType: 'date' })
], Submission.prototype, "effectiveDate", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Expiration Date', fieldType: 'date' })
], Submission.prototype, "expirationDate", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'PayrollClassCode',
        isList: true,
        hasColumn: true,
    })
], Submission.prototype, "payrollClassCodes", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Submitted By', fieldType: 'User', jsonStorage: true })
], Submission.prototype, "submittedBy", void 0);
__decorate([
    FieldData({ recordTypeId })
], Submission.prototype, "submittedById", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Notes' })
], Submission.prototype, "notes", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'uuid',
        referenceType: 'PolicyBillingScheduleType',
    })
], Submission.prototype, "billingScheduleTypeId", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'PolicyBillingScheduleType',
        label: 'Billing Schedule Type',
        jsonStorage: true,
    })
], Submission.prototype, "billingScheduleType", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Submission Name' })
], Submission.prototype, "submissionName", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Address', fieldType: 'Address', jsonStorage: true })
], Submission.prototype, "address", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'FEIN', jsonStorage: true })
], Submission.prototype, "federalEin", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Insured', jsonStorage: true })
], Submission.prototype, "insured", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'decimal', label: 'Earned Premium', jsonStorage: true })
], Submission.prototype, "earnedPremium", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', label: 'Total Incurred', jsonStorage: true })
], Submission.prototype, "totalIncurred", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'decimal', label: 'Loss Ratio', jsonStorage: true })
], Submission.prototype, "lossRatio", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Coverages',
        codeSet: 'coverages',
        fieldType: 'codelist',
        isList: true,
        jsonStorage: true,
    })
], Submission.prototype, "coverages", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'codelist', jsonStorage: true, codeEnum: SubmissionTypes })
], Submission.prototype, "submissionType", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Location Based Payroll',
        fieldType: 'boolean',
        jsonStorage: true,
    })
], Submission.prototype, "locationBasedPayroll", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Policy Locations',
        isList: true,
        fieldType: 'Location',
        jsonStorage: true,
    })
], Submission.prototype, "policyLocations", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Quote ID', fieldType: 'uuid' })
], Submission.prototype, "quoteId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Quote', fieldType: 'Quote' })
], Submission.prototype, "quote", void 0);
Submission = __decorate([
    RecordTypeData({
        id: recordTypeId,
        hasBackingTable: true,
        displayNameSingular: 'Submission',
        displayNamePlural: 'Submissions',
        supports: {
            views: true,
            files: true,
            docTemplates: true,
            globalSearch: true,
        },
        dataModelCustomization: {
            allowCustomFields: true,
        },
        trailMapTypes: [
            TrailMapType.HomePage,
            TrailMapType.DataEntry,
            TrailMapType.Detail,
            TrailMapType.DataEntryStart,
        ],
        globalSearchPaths: ['federalEin', 'submissionName'],
        lookupProperties: {
            resultProperties: ['submissionName', 'createdUTC'],
            filterProperties: ['submissionName'],
        },
        roleCategory: RoleCategories.Submissions,
        recordHistoryConfig: {
            enable: true,
        },
        workflow: {
            enabled: true,
            generalEvents: true,
        },
        usesNewDataModel: true,
    })
], Submission);
export { Submission };
